body {
  margin: 0;
  font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.dark {
  background-color: black;
  color: white;
  margin-top: 0%;
  margin-bottom: 0%;
  padding-left: 30%;
  padding-right: 30%;
  padding-bottom: 30px;
}

.dark h1 {
  font-weight: 400;
  color: #ffc700;
  margin-bottom: 0%;
  line-height: 2.5ex;
}

.dark h4 {
  font-weight: 400;

  margin-bottom: 0%;
}

.zero {
  margin-top: 0%;
}

/* p {
  margin-bottom: 0%;
  padding-bottom: 20px;
} */

.box {
  display: flex;
  justify-content: center;
}
img {
  max-width: 100%;
  height: auto;
}

.site-header {
  display: flex;
  background-color: black;
  padding: 10px;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}

.brand,
.nav,
.button {
  background-color: #00954c;
  
}

.nav {
  flex: 1;
  /* flex: 0 0 90%; */
}

.brand {
  flex: 0 0 100px;
  /* flex: 0 0 10%; */
}

.boxes {
  padding-top: 2%;
  padding-left: 20%;
  padding-right: 20%;
  display: flex;
  justify-content: center;
  background-color: black;
  gap: 1em;
}

.footer {
  background-color: black;
  padding-bottom: 30px;
  color: silver;
  font-size: 14px;
}
.footer span {
  font-weight: 600;
}

.no-wrap {
  flex-shrink: 0;
}

.no-wrap p {
  margin-bottom: 0%;
  margin-top: 0%;
}

.bx {
  padding-top: 10px;
  font-size: 18px;
  color: white;
}

.boldit {
  color: #ffc700;
  font-weight: bold;
}

.backcolor {
  background: linear-gradient(180deg, black 50%, black 50%);
  padding-bottom: 5%;
}

.logo {
  width: 30%;
}

input[type=text], input[type=email], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
}

.submission {
  background-color: #ffc700;
  color: black;
  cursor: pointer;
}

.submission:hover {
  background-color: #8a8d8e;
}

.confirmed {
  background-color: #8a8d8e;
  color: black;
}

.detailform {
  /* border-radius: 5px; */
  background-color: black;
  padding: 40px;
}
.detailform p {
  margin: 0%;
  text-align: left;
  color: cornsilk;
}

.greyedout {
  color: red;
}

select option:first-child{
  color: red;
}

.errorlist {
  margin-top: 0%;
  margin-bottom: 0%;
  background-color: #00954c;
  color: cornsilk;
  list-style: none;
}

.formtext {
  color: white;
  background-color: black;
  padding: 0px 20%;
  font-size: 24px;
  line-height:2.5ex
}

@media only screen and (max-width: 800px) {
  .bx {
    /* padding-top: 10px; */
    font-size: 12px;
  }
  .formtext {
    font-size: 14px;
  }
}

